<template>
  <amplify-authenticator>
    <v-app>
      <div>
        <img class="mt-2" src="../assets/bg.jpg" width="100%" />
        <div v-if="countDownTimer !== ''" align="center">
          <h2 class="mt-5"><span class="bg-colo-bmx white--text pa-1">Vous n'êtes pas encore inscrit ?</span></h2>
          <h2 class="mt-3"><a href="https://forms.office.com/Pages/ResponsePage.aspx?id=OlTArnPMpE-eKs9Srfm3iJleMJpYUEBGp3Ivtz7IhkpUQ1FBV00yQUxLVUxJUFlRTE1ZNUVYVkM0Ny4u"
              class="bg-colo-bmx white--text pa-1 text-deco-none" target="blank">Cliquez-ici</a></h2><br>          
          <h2 class="display-1 font-weight-bold color-bmx">
            <strong> {{ countDownTimer.days }}</strong> <small>JOURS </small>
            <strong> {{ countDownTimer.hours }}</strong> <small>HEURES </small>
            <strong> {{ countDownTimer.minutes }}</strong> <small>MINUTES </small>
            <strong> {{ countDownTimer.seconds }}</strong> <small>SECONDES</small>       
          </h2>
          <h2 class="display-1 font-weight-bold color-bmx">AVANT LE DEBUT DE L'EVENEMENT</h2>
        </div> 
        <div id="live" v-else-if="countDownTimer == ''">           
           <v-container fluid padless>
            <v-row align="top" justify="center">
              <v-col cols="2"></v-col>
              <v-col cols="5">
                <div ref="player" class="container inner player-wrapper">
                  <div class="pos-absolute full-width full-height top-0">
                    <video id="video-player" class="el-player" poster="./../assets/coming-soon-black.png" controls
                      playsinline></video>
                    <div>En fonction de votre navigateur Internet, merci de vérifier que le son est bien activé sur le
                      lecteur
                      vidéo. <v-img class="mx-auto" src="../assets/volume.png" max-height="32" max-width="32"></v-img>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="4" style="text-align: center">
                <v-card outlined shaped elevation="15" width="390" height="380" class="mx-auto mt-9 ">
                  <v-card-text class="text-lg-h6 text-center mr-2">Pour poser vos questions en direct : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br> 
                    scannez ce QR Code &nbsp;&nbsp;&nbsp;&nbsp;</v-card-text>
                  <v-img class="mx-auto" src="../assets/qr.png" max-height="120" max-width="120"></v-img>
                  <v-card-text class="text-lg-h6 text-center pr-2">
                    ou allez sur &nbsp;&nbsp;&nbsp;&nbsp;<br>
                    <strong>www.menti.com &nbsp;&nbsp;&nbsp;&nbsp;</strong> <br>
                    et entrez le code &nbsp;&nbsp;&nbsp;&nbsp;<br>
                    <strong>5337 1287 &nbsp;&nbsp;&nbsp;&nbsp;</strong>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="bg2 pb-10">
          <v-container>
            <v-row justify="center">
              <v-col cols="1"></v-col>
              <v-col cols="4" class="text-center" justify="center">
                <div class="title-block2 mt-10" justify="center">
                  <v-btn text @click="displayOrHide('displayProgram')"
                    class="white--text mt-2 mb-2 display-1 font-weight-bold">PROGRAMME</v-btn>
                </div>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="4" class="text-center" justify="center">
                <div class="title-block2 mt-10" justify="center">
                  <v-btn text @click="displayOrHide('displayIntervenants')"
                    class="white--text mt-2 mb-2 display-1 font-weight-bold">INTERVENANTS</v-btn>
                </div>
              </v-col>
              <v-col cols="1"></v-col>
            </v-row>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8" class="white--text pr-3 text-center" justify="center">
                <h3>
                  Journée animée par Alain Crozier – CLE@N COSMETIC CONSULTING
                </h3>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <div v-if="displayProgram" class="program">
              <v-expansion-panels class="mb-6">
                <v-row style="width:100%" class="mt-12" v-for="program in programs" :key="program.id">
                  <v-col class="mt-8 text-right" cols="3">
                    <h4 class="white--text pr-3" style="font-size: 1.5em">
                      {{ program.hour }}
                    </h4>
                  </v-col>
                  <v-col cols="8" style="text-align: left">
                    <v-row>
                      <v-col cols="2" class="font-weight-bold color-bmx pt-12" style="background-color: white">{{
                          program.title
                      }}</v-col>
                      <v-col cols="8" style="background-color: white">
                        <v-expansion-panel>
                          <v-expansion-panel-header class="pl-0" :hide-actions="!program.isSummary"
                            style="color: #00427f" expand-icon="mdi-menu-down">
                            <span class="program-header pr-3">{{ program.header }}</span>
                            <template v-slot:actions>
                              <v-btn small color="#00427f" dark>Voir le résumé</v-btn><br><br>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            {{ program.text }}
                            <div class="mt-2" v-if="program.list">
                              <p class="ma-0" v-for="item in program.list" :key="item">
                                {{ item }}
                              </p>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <p class="color-bmx mt-2 font-weight-bold">
                          {{ program.presenter }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panels>
            </div>
            <div v-if="displayIntervenants" class="mt-12 intervenants">
              <v-row v-for="presenter in presenters" :key="presenter.id">
                <v-col cols="1"></v-col>
                <v-col cols="2" class="pt-5 ma-0">
                  <img class="ml-15 mt-4" :src="getImgUrl(presenter.photoName)" v-bind:alt="presenter.photoName"
                    width="50%" />
                  <h3 class="pa-0 mt-0 mb-0 ml-15 white--text">
                    {{ presenter.order }}
                  </h3>
                </v-col>
                <v-col cols="8">
                  <v-card max-width="1000" outlined>
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="text-overline mb-4">
                          {{ presenter.company }}
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                          {{ presenter.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                            presenter.job
                        }}</v-list-item-subtitle>                
                        <template v-if="isList(presenter.experience)">  
                            <ul>
                            <li class="mt-2 text-left" v-for="item in getList(presenter.experience)" :key="item">
                                {{ item }}
                            </li></ul>
                        </template>                        
                        <p v-else class="mt-2 text-left" >{{ presenter.experience }}</p>
                      </v-list-item-content>
                    </v-list-item>

                    <v-card-actions>
                      <a :href="`${linkedInUrl}/${presenter.linkedInID}`" class="text-deco-none" target="blank">
                        <v-btn color="#00427F" outlined rounded text class="btn-link">
                          <v-icon> mdi-linkedin </v-icon>
                          Accèder au profil LinkedIn
                        </v-btn>
                      </a>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </div>
      </div>
      <v-footer class="mt-10" color="white">
        <v-row class="mb-5">
          <v-col cols="3">
            <p class="ml-7" style="font-size: 1.5em; text-align: left; color: #a4a7c0">
              PIONEERING DIAGNOSTICS
            </p>
          </v-col>
          <v-col cols="5"></v-col>
          <v-col cols="3" class="font-weight-bold" style="text-align: right">
            <div>
              <a href="https://www.biomerieux-industry.com/fr/legal-notice" target="blank">
                <span  style="color: #00427f">Politique de confidentialité</span>
              </a>
            </div>
            <div class="mt-2">
              <a  href="https://www.biomerieux-industry.com/fr" target="blank">
                <span style="color: #00427f">En savoir plus sur bioMérieux</span>
              </a>
            </div>
            <div class="mt-2">
              <a href="mailto:lise.losseff@biomerieux.com" target="blank">
                <span style="color: #00427f">Un problème ? lise.losseff@biomerieux.com</span>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
  </amplify-authenticator>
</template>

<script>
import {
  PlayerEventType,
  PlayerState
} from "amazon-ivs-player";

export default {
  name: "Home",
  props: {
    msg: String,
    player: null
  },

  data: () => ({
    countDownDate: new Date(process.env.VUE_APP_EVENT_DATE_TIME).getTime(),
    countDownTimer: "",
    displayProgram: true,
    linkedInUrl: "https://www.linkedin.com/in",
    displayIntervenants: false,
    programs: [
      {
        id: 1,
        hour: "9h00 - 9h10",
        title: "Introduction                   ",
        presenter: "Viviane Lefebvre - BIOMERIEUX | Alain Crozier – CLE@N COSMETIC CONSULTING",
        header:
          "Introduction",
        isSummary: true,
        list: [],
        text: "",
      },
      {
        id: 2,
        hour: "9h10 - 9h45",
        title: "Conférence #1",
        presenter: "Aurore Sergeant - BIOMERIEUX | Mélanie Pelissard – BIOMERIEUX",
        header:
          "Agir en entreprise responsable pour augmenter notre impact positif : stratégie RSE globale et focus sur la démarche d’éco-conception",
        isSummary: true,
        list: [],
        text: "A  venir",
      },
      {
        id: 3,
        hour: "9h45 – 10h20",
        title: "Conférence #2",
        presenter: "Caroline Bassoni - COSMED",
        header:
          "Enjeux de développement durable et pression règlementaire : quelles implications pour la filière cosmétique ?",
        isSummary: true,
        list: [],
        text: "Dans un contexte de pression règlementaire autour du développement durable toujours plus forte, quels sont les enjeux industriels et les impacts associés et de quelle manière l’industrie des cosmétiques peut malgré tout y trouver des opportunités. Nous verrons avec des illustrations concrètes déjà mises en place comment le pacte vert va au final contribuer à l’innovation et à des engagements environnementaux forts de la part des marques.",
      },
      {
        id: 4,
        hour: "10h45 - 11H20",
        title: "Conférence #3",
        presenter: "Mathilde Augereau – CQS EXPERTS",
        header:
          "Le management du risque au service de la démarche RSE",
        isSummary: true,
        list: [
          "-	En quoi le management du risque est-il un outil dans la démarche RSE?",
          "-	Qu'est-ce que le management du risque ?",
          "-	Pourquoi réaliser un management du risque ? ",
          "-	Comment mettre en œuvre le management du risque ? ",
          "-	Comment optimiser la gestion du risque ?",
          "-	Quelles sont les méthodes d'analyse de risques principalement utilisées ?",
          "-	Cas d ’études",
        ],
        text: "",
      },
      {
        id: 5,
        hour: "11h20– 11H40",
        title: "Conférence #4",
        presenter: "Séverine Roullet-Furnemont – PIERRE FABRE / Pascale Cochet – YVES ROCHER",
        header:
          "Comment transcrire sa stratégie RSE sur le produit fini ?",
        isSummary: true,
        list: [],
        text: "",
      },
      {
        id: 6,
        hour: "11h50 – 12h30",
        title: "Table ronde",
        presenter: "Conférenciers & Experts du secteur",
        header:
          "Développement durable : Quelles sont les orientations industrielles privilégiées ? Quels bénéfices pour le consommateur ? ",
        isSummary: true,
        list: [],
        text: "",
      },      
      {
        id: 7,
        hour: "14h15 - 14h30",
        title: "Introduction",
        presenter: "Alain Crozier – CLE@N COSMETIC CONSULTING",
        header:
          "Introduction",
        isSummary: true,
        list: [],
        text: "Introduction par Alain Crozier – CLE@N COSMETIC CONSULTING",
      },
      {
        id: 8,
        hour: "14h30 – 15h05",
        title: "Conférence #5",
        presenter: "Gael Malet – L’OREAL",
        header:
          "Produits rechargeables sur le lieu de vente : référentiels et focus hygiène",
        isSummary: true,
        list: [
            "Que ce soit en réponse à la forte attente des consommateurs, ou dû à un renforcement de la pression réglementaire (loi AGEC, loi Climat et Résilience…), la transition vers une économie circulaire est désormais une réalité, en particulier dans un objectif de limitation des déchets. Dans ce contexte, la pratique de vente en vrac, déjà bien implantée dans le secteur alimentaire, est en plein essor dans l’industrie cosmétique.",
            "Si la recharge sur le lieu de vente semble à priori une opération simple, elle représente en fait de nombreux défis, notamment concernant la qualité microbiologique des produits finis. De nombreux paramètres (type et composition de la formule, gestion du contenant à recharger, design et gestion de la station de recharge, recharge en libre-service ou service assisté…) sont à considérer afin d’évaluer la  maîtrise du risque de contamination microbiologique et d’ainsi répondre aux exigences réglementaires visant à assurer la sécurité sanitaire des consommateurs."
            ],
        text: "",
      },
      {
        id: 8,
        hour: "15h05 – 15h40",
        title: "Conférence #6",
        presenter: "Alain Berberian – CLARANOR",
        header:
          "Apports de la décontamination par lumière pulsée pour cosmétique durable. ",
        isSummary: true,
        list: [],
        text: "A venir",
      },
      {
        id: 9,
        hour: "15h40 – 16h15",
        title: "Conférence #7",
        presenter: "Arnaud Lancelot - COZIE",
        header:
          "Assurer Hygiène et Traçabilité pour le Vrac, le Refill, la Consigne… : rendez-vous en terre inconnue !",
        isSummary: true,
        list: [],
        text: "Positionnement de COZIE d’entreprise éco-responsable avec une solution de refill et vrac et consigne ; focus sur la sécurité sanitaire ; traçabilité en référence avec les exigences réglementaires cosmétiques. ; approche plus technique et réglementaire que commerciale.",
      },
      {
        id: 10,
        hour: "16h15 – 16h30",
        title: "Q&A",
        presenter: "",
        header:
          "Questions et réponses",
        isSummary: true,
        list: [],
        text: "Session de questions and réponses",
      },
      {
        id: 11,
        hour: "16h30",
        title: "Conclusions de la journée",
        presenter: "",
        header:
          "Les conclusions de la journée",
        isSummary: true,                                                         
        list: [],
        text: "                                                                          ",
      },
    ],
    presenters: [
      {
        id: 1,
        name: "Alain Crozier",
        photoName: "alain-crozier",
        company: "CLE@N COSMETIC CONSULTING",
        job: "Docteur en microbiologie & Consultant ",
        order: "",
        linkedInID: "crozier-alain-21783249/",
        experience:
          "Docteur en microbiologie il a occupé différentes responsabilités de Direction au sein du groupe L’Oréal puis Johnson & Johnson, principalement dans le domaine de la microbiologie des produits cosmétiques et de l’ingénierie ultra propre des équipements de production. Depuis 2016, Consultant indépendant sous le label Cle@n Cosmetic Consulting, et participation très régulière à différents congrès en tant que modérateur et/ou conférencier. ",
      },
      {
        id: 2,
        name: "Aurore Sergeant",
        photoName: "aurore-sergeant",
        company: "BIOMERIEUX",
        job: "A venir",
        order: "Conférence #1",
        linkedInID: "",
        experience:
          "",
      },
      {
        id: 3,
        name: "Mélanie Pelissard",
        photoName: "melanie-pelissard",
        company: "BIOMERIEUX",
        job: "Product Sustainability Manager",
        order: "Conférence #1",
        linkedInID: "melaniepelissard/",
        experience:
          "Depuis 2018 chez bioMérieux pour mettre en place et déployer une politique d’éco-conception, contribuant au pilier PLANET de sa stratégie RSEPELISSAR<li>15 années passées dans le secteur automobile sur les sujets environnementaux (éco-conception, ACV, empreinte carbone, économie circulaire, transition énergétique du parc automobile : batteries des véhicules électriques et énergies alternatives)<li>Des expériences multiples chez des énergéticiens (ENGIE, IFPEN) et chimistes (AKZO NOBEL)<li>Ingénieur généraliste des Mines",
      },
      {
        id: 4,
        name: "Caroline Bassoni ",
        photoName: "caroline-bassoni",        
        company: "COSMED",
        job: "Directrice Affaires Règlementaires",
        order: "Conférence #2",
        linkedInID: "caroline-bassoni/",
        experience:
          "Au sein de Cosmed l'association professionnelle des PMEs de la filière cosmétique qui fédère près de 1000 entreprises cosmétiques françaises et Européennes, Caroline BASSONI représente, définit et défend les intérêts du secteur cosmétique au travers de plusieurs institutions et commissions, au niveau Français et Européen.",
      },
      {
        id: 5,
        name: "Mathilde Augereau",
        photoName: "mathilde-augereau",        
        company: "CQS EXPERTS",
        job: "Consultante, formatrice et auditrice",
        order: "Conférence #3",
        linkedInID: "mathilde-augereau/",
        experience:
          "1994 – 2018 Responsable qualité et technique dans 2 laboratoires d’essais LABILAIT et MICROSEPT. Evaluatrice pour plusieurs organismes d’accréditation en microbiologie alimentaire et des eaux depuis plus de 20 ans. Consultante, formatrice et auditrice pour CQS depuis 2018",
      },
      {
        id: 6,
        name: "Gael Malet",
        photoName: "gael-malet",        
        company: "L’OREAL",
        job: "Responsable Laboratoire Microbiologie – R&I France",
        order: "Conférence #5",
        linkedInID: "",
        experience:
          "Gaël Malet a rejoint le Groupe L'OREAL en 1998 après avoir obtenu son DESS (Master 2) en Microbiologie Appliquée et Génie Biologique. En tant qu'ingénieur projet au sein de l'équipe Microbiologie Corporate du département des Opérations, il a été impliqué dans divers sujets relatifs à la qualité microbiologique de conformité des produits cosmétiques (qualité des matières premières, méthodes de contrôle, conception hygiénique des équipements de production, etc.). Il rejoint ensuite l'une des usines du Groupe en tant que Responsable Assurance Qualité, avant d’intégrer en 2007 l'équipe Microbiologie Corporate du département Recherche & Innovation. Depuis 2015, il est responsable du laboratoire de microbiologie R&I France et traite ainsi des sujets liés à la protection antimicrobienne des formules.",
      },
      {
        id: 7,
        name: "Alain Berberian",
        photoName: "alain-berberian",        
        company: "CLARANOR",
        job: "Responsable Laboratoire Microbiologie ",
        order: "Conférence #6",
        linkedInID: "alain-berberian-5274b024b/",
        experience:
          "30 ans en étroite collaboration avec l’industrie. 13 ans passés dans un centre technique national de conservation des aliments (CTCPA) comme chef de projets en sécurité alimentaire puis comme responsable du laboratoire de Microbiologie. Depuis 2010, chez Claranor comme Responsable Microbiologie pour l’évaluation et la validation des performances microbiologiques de la technologie lumière pulsée, au service de l’industrie Agro-Alimentaire, pharmaceutique et cosmétique.",
      },
      {
        id: 9,
        name: "Arnaud Lancelot",
        photoName: "arnaud-lancelot",
        company: "COZIE",
        job: "Directeur Général",
        order: "Conférence #7",
        linkedInID: "arnaud-lancelot/",
        experience:
          "Arnaud est co-fondateur de Cozie, marque de soins cosmétiques Consignés et rechargeables en vrac. Il accompagne les acteurs de l’industrie cosmétique (Yves Rocher, Clarins, Pierre Fabre, Expanscience, Naos…) dans leur transition vers l’économie circulaire, en les conseillant et en leur apportant des solutions concrètes.",
      },     
      {
        id: 10,
        name: "Pascale Cochet",
        photoName: "pascale-cochet",
        company: "GROUPE ROCHER",
        job: "Directrice Sécurité Humaine et Environnementale Produits",
        order: "Conférence #4",
        linkedInID: "pascale-cochet-0720519/",
        experience:
          "Pharmacien industrie<li>Expérience majoritaire en affaires réglementaires<li>Démarrage en industrie pharmaceutique chez Dolisos puis Johnson & Johnson, puis Laboratoires Expanscience et tout récemment en Février 2022 au sein du Groupe Rocher au poste de Directrice Sécurité Humaine et Environnementale Produit",
      },    
      {
        id: 11,
        name: "Séverine ROULLET-FURNEMONT",
        photoName: "severine-roullet-furnemont",
        company: "Pierre Fabre",
        job: "Directrice RSE et Développement Durable",
        order: "Conférence #4",
        linkedInID: "severine-roullet-furnemont-10ba4489/",
        experience:
          "Après 15 ans passés dans la protection de l’environnement au sein du laboratoire Français du Fractionnement et des Biotechnologies puis du Groupe Pierre Fabre, Séverine Roullet-Furnemont anime depuis 13 ans la politique développement durable de ce dernier.<li>Séverine est également administrateur chez ADELPHE et ECOPAR et élu au Comité de Bassin ADOUR GARONNE.",
      },    
    ],
  }),
  computed: {},
  mounted() {
    let that = this;
    let x = setInterval(function () {
      const now = new Date().getTime();
      const distance = that.countDownDate - now;
      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
        that.countDownTimer = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        }

        if (distance < 0) {
          clearInterval(x);
          that.countDownTimer = "";
        }        
      }
    }, 1000);

    const IVSPlayer = document.getElementById("ivsPlayer");
    IVSPlayer.async = true;

    const player = window.IVSPlayer.create();
    console.log("IVS Player version:", player.getVersion());
    player.attachHTMLVideoElement(document.getElementById("video-player"));

    player.addEventListener(PlayerState.PLAYING, function () {
      console.log("Player State - PLAYING");
    });

    player.addEventListener(PlayerState.READY, function () {
      console.log("Player State - READY");
    });

    player.addEventListener(PlayerEventType.ERROR, function (err) {
      console.warn("Player Event - ERROR:", err);
    });

    player.addEventListener(PlayerEventType.AUDIO_BLOCKED, function () {
      if (player.isMuted()) {
        player.setMuted(false);
        console.log('Player.isMuted() ? --> ' + player.isMuted())
      }
    });

    player.setAutoplay(true);
    player.load(process.env.VUE_APP_AWS_IVS_PLAYBACK_URL);
    player.setVolume(1);
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    },
    getImgUrl(pic) {
      return require("../assets/" + pic + ".jpg");
    },
    displayOrHide(container) {
      console.log(container);
      if (container === "displayProgram") {
        this.displayProgram = true;
        this.displayIntervenants = false;
      } else if (container === "displayIntervenants") {
        this.displayIntervenants = true;
        this.displayProgram = false;
      }

    },
    isList(content){
        return content.includes("<li>");
    }, 
    getList(content){
        return content.split("<li>");
    }, 
    videoFullScreen() {
      let elem = document.getElementById("video-player");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.text-deco-none {
  text-decoration: none !important;
}

.color-bmx {
  color: #00427f;
}
.btn-link a{
  text-decoration: none;
}
.bg2 {
  margin-top: 30px;
  padding: 0;
  margin-left: 0;
  width: 100%;
  height: auto;
  background: url("../assets/bg2.jpg") no-repeat;
  background-position: center;
}

.title-block2 {
  width: 100%;
  background-color: rgba(7, 7, 7, 0.5);
  color: white;
  padding: 0px 5px 0px 5px;
}

.font-benton {
  font-family: "Benton Sans";
}

.bg-colo-bmx {
  background-color: #00427f;
}

.program-header {
  line-height: 1.5em;
}

.inner {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  margin: 0 auto;
  padding-top: 1px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
}

.player-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  z-index: 1;
  margin-left: -30px;
}

.aspect-spacer {
  padding-bottom: 1.25%;
}

.el-player {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: var(--radius);
}

#video-player:-webkit-full-screen {
  width: 100%;
  height: 100%;
}
</style>
